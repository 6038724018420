import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBlogPostById } from '../services/api';
import ReactMarkdown from 'react-markdown';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { FaCalendarAlt } from 'react-icons/fa';

const BlogPostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await fetchBlogPostById(id);
        setPost(result);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };
    fetchPost();
  }, [id]);

  if (!post) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-xl text-gray-700">Loading blog post...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  const formattedDate = post.created_at
    ? new Date(post.created_at).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : '';

  return (
    <>
      <Menu />

      {/* Hero Section */}
      <section className="bg-black text-white py-16 px-6">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6 leading-tight tracking-tight">
            {post.title}
          </h1>
          {formattedDate && (
            <div className="flex items-center justify-center text-gray-300">
              <FaCalendarAlt className="h-5 w-5 mr-2" />
              <span className="text-lg">{formattedDate}</span>
            </div>
          )}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      <section className="py-24 bg-white">
        <div className="container mx-auto px-6 lg:px-12">
          <div className="prose prose-xl mx-auto text-gray-800 leading-relaxed">
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogPostDetail;