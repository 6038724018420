import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { FaCalendarAlt, FaCreditCard, FaTimes } from 'react-icons/fa';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found. Please log in.');
        }
        const response = await axiosInstance.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError(err.response?.data?.message || 'Failed to load profile data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };

  const handleSubscribe = () => {
    navigate('/pricing');
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found.');
      }
      const response = await axiosInstance.post(
        '/cancel-subscription',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message || 'Subscription canceled successfully.');
      const refreshedResponse = await axiosInstance.get('/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(refreshedResponse.data);
    } catch (err) {
      console.error('Error canceling subscription:', err);
      alert(err.response?.data?.message || 'Failed to cancel subscription. Please try again.');
    }
  };

  const handleManagePayment = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found.');
      }
      const response = await axiosInstance.get('/customer-portal', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { url } = response.data;
      window.location.href = url;
    } catch (err) {
      console.error('Error accessing customer portal:', err);
      alert(err.response?.data?.message || 'Failed to access payment methods. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-lg text-gray-700">Loading profile...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <p className="text-lg text-red-500">{error}</p>
        </div>
        <Footer />
      </>
    );
  }

  if (!userData) {
    return null;
  }

  const {
    name,
    email,
    subscriptionStatus,
    keywordSearchCount,
    blogPostCount,
    subscriptionAmount,
    subscriptionRenewalDate,
  } = userData;

  const isTrial = subscriptionStatus === 'trial';
  const isPremium = subscriptionStatus === 'active';
  const maxKeywordSearch = isTrial ? 10 : 500;
  const maxBlogPosts = isTrial ? 5 : 100;

  return (
    <>
      <Menu />

      {/* Hero Section */}
      <section className="bg-black text-white py-16 px-6">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-5xl font-bold mb-6 leading-tight tracking-tight">
            Profile
          </h2>
          <p className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed">
            Manage your account and subscription details
          </p>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      <section className="py-24 bg-white">
        <div className="container mx-auto px-6 lg:px-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* User Data Block */}
            <div className="bg-white border border-gray-200 shadow-sm rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-6 text-gray-900">User Information</h3>
              <div className="mb-6">
                <label className="block text-gray-600 mb-1 font-medium">Name</label>
                <p className="text-lg text-gray-800">{name}</p>
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 mb-1 font-medium">Email</label>
                <p className="text-lg text-gray-800">{email}</p>
              </div>
            </div>

            {/* Account Data Block */}
            <div className="bg-white border border-gray-200 shadow-sm rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-6 text-gray-900">Account Information</h3>
              <div className="mb-6">
                <label className="block text-gray-600 mb-1 font-medium">Subscription Type</label>
                <p className={`text-lg font-medium ${isPremium ? 'text-green-600' : 'text-blue-600'}`}>
                  {isPremium ? 'Premium' : 'Trial'}
                </p>
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 mb-1 font-medium">Keyword Searches Left</label>
                <p className="text-lg text-gray-800">{keywordSearchCount} / {maxKeywordSearch}</p>
              </div>
              <div className="mb-8">
                <label className="block text-gray-600 mb-1 font-medium">Blog Posts Left</label>
                <p className="text-lg text-gray-800">{blogPostCount} / {maxBlogPosts}</p>
              </div>

              {/* Trial Users */}
              {isTrial && (keywordSearchCount >= maxKeywordSearch || blogPostCount >= maxBlogPosts) && (
                <div className="mb-8">
                  <p className="text-gray-700 mb-4">
                    You've reached your trial limits. Upgrade to Premium to continue enjoying Ranqio's benefits!
                  </p>
                  <button
                    onClick={handleSubscribe}
                    className="w-full bg-black text-white py-3 px-6 rounded hover:bg-gray-800 transition text-base font-medium"
                  >
                    Upgrade to Premium
                  </button>
                </div>
              )}

              {/* Premium Users */}
              {isPremium && (
                <>
                  <div className="mb-6">
                    <label className="block text-gray-600 mb-1 font-medium">Subscription Amount</label>
                    <p className="text-lg text-gray-800">${subscriptionAmount}/month</p>
                  </div>
                  <div className="mb-8">
                    <label className="block text-gray-600 mb-1 font-medium">Renewal Date</label>
                    <p className="text-lg text-gray-800">
                      {subscriptionRenewalDate ? moment(subscriptionRenewalDate).format('MMMM Do, YYYY') : 'N/A'}
                    </p>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <button
                      onClick={handleManagePayment}
                      className="flex items-center justify-center bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300 transition text-base font-medium"
                    >
                      <FaCreditCard className="mr-2" />
                      Manage Payment Methods
                    </button>
                    <button
                      onClick={handleCancelSubscription}
                      className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition text-base font-medium"
                    >
                      <FaTimes className="mr-2" />
                      Cancel Subscription
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Logout Button */}
          <div className="flex justify-center mt-12">
            <button
              onClick={handleLogout}
              className="bg-red-600 text-white py-3 px-6 rounded hover:bg-red-700 transition text-base font-medium"
            >
              Logout
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Profile;