import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import axiosInstance from '../services/axiosInstance';

const Menu = () => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [question, setQuestion] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch user email when component mounts
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found.');
        }

        const response = await axiosInstance.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserEmail(response.data.email);
      } catch (err) {
        console.error('Error fetching user email:', err);
      }
    };

    fetchUserEmail();
  }, []);

  const handleSubmitQuestion = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      user_email: userEmail,
      question: question,
    };

    emailjs
      .send('service_w0pnzfj', 'template_273xm36', templateParams, 'ywHeeL1xFz31m6upy')
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setShowHelpModal(false);
          setLoading(false);
        },
        (error) => {
          console.log('FAILED...', error);
          setLoading(false);
        }
      );
  };

  // Reusable classes for links: no underline by default, subtle hover line
  const linkClasses =
    "relative text-black font-medium no-underline transition duration-200 " +
    "after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:bg-black " +
    "after:w-0 hover:after:w-full focus:outline-none";

  return (
    <>
      {/* Navigation Bar (No Gradient) */}
      <nav className="bg-white border-b border-gray-200 shadow-sm py-4">
        <div className="container mx-auto px-6 lg:px-12 flex justify-between items-center">
          {/* Logo */}
          <Link
            to="/keywords"
            className="text-2xl font-bold text-black tracking-tight"
            style={{ fontFamily: 'Poppins, sans-serif', textDecoration: 'none' }}
          >
            RANQIO
          </Link>

          {/* Navigation Links */}
          <div className="space-x-8 flex items-center">
            <Link
              to="/keywords"
              className={linkClasses}
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Keyword Ideas
            </Link>
            <Link
              to="/blogposts"
              className={linkClasses}
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Blog Posts
            </Link>
            <Link
              to="/how-grow"
              className={linkClasses}
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Learn How to Grow
            </Link>
            <button
              onClick={() => setShowHelpModal(true)}
              className={linkClasses}
              style={{ fontFamily: 'Poppins, sans-serif', background: 'none' }}
            >
              Need Help?
            </button>
            <Link
              to="/profile"
              className={linkClasses}
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Profile
            </Link>
          </div>
        </div>
      </nav>

      {/* Help Modal */}
      {showHelpModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4">
          <div className="relative bg-white rounded-md shadow-lg p-8 max-w-md w-full">
            <h3
              className="text-xl font-semibold text-gray-900 mb-6 text-center"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Need Help?
            </h3>
            <form onSubmit={handleSubmitQuestion}>
              <label className="block text-gray-700 mb-2 text-sm font-medium">
                Send us your question, and we’ll respond to your email within 24 hours.
              </label>
              <textarea
                className="w-full border rounded-md p-2 mb-4 text-sm focus:outline-none focus:border-gray-500"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
                style={{ fontFamily: 'Poppins, sans-serif' }}
              />
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition text-sm font-medium"
                style={{ fontFamily: 'Poppins, sans-serif' }}
              >
                {loading ? 'Sending...' : 'Submit'}
              </button>
            </form>
            <button
              onClick={() => setShowHelpModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-base"
              style={{ textDecoration: 'none' }}
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;