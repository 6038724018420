// Import necessary modules
import React, { useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import '../lps/seoGuideEbook.css';
import Footer from '../components/Footer';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/leads', { email });
      if (response.status === 200) {
        setMessage('Success! Check your email for the ebook link.');
        setEmail(''); // Clear the email field
      } else {
        setMessage('Something went wrong. Please try again later.');
      }
    } catch (error) {
      setMessage('Error: Unable to submit your email. Please try again.');
    }
  };

  return (
    <>
      {/* Landing Page Wrapper */}
      <div className="seo-guide-landing-page">
        {/* Hero Section */}
        <div className="bg-black text-white py-24 px-6">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-bold mb-6 leading-tight">
              Transform Your SEO in Just 5 Minutes a Day
            </h1>
            <p className="text-xl mb-12">
              Busy startup founders like you don’t have time to master complex SEO strategies. <br /> 
              <strong>But here’s the truth:</strong> every day you delay investing in SEO is a missed opportunity to secure your future growth.
            </p>
            <form className="bg-white text-black p-6 rounded-md" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-4 text-lg mb-4 border rounded-md"
                required
              />
              <button
                type="submit"
                className="bg-black text-white py-3 px-6 rounded-md text-lg hover:bg-gray-800 transition"
              >
                Get My Free Ebook
              </button>
            </form>
            {message && (
              <p className="mt-4 text-lg font-medium text-green-500">
                {message}
              </p>
            )}
          </div>
        </div>

        {/* Promise Section */}
        <section className="bg-gray-900 text-white py-16 px-6">
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-semibold mb-6">Why You Can’t Afford to Ignore SEO Any Longer</h2>
            <p className="text-lg mb-8">
              Most startups see exponential growth when they invest early in SEO.<br /> Waiting for the <strong>“right time”</strong> means falling behind competitors who are already capturing your market.
            </p>
            <p className="text-lg font-bold">
              As a startup founder, you’re juggling a hundred priorities. It’s easy to push SEO to the back burner because:
            </p>
            <ul className="mt-4 space-y-4">
              <li>
                <strong>“SEO Takes Too Long to Show Results”:</strong><br /> 
                While it’s true that SEO isn’t instant, every day you delay is another day your competitors get ahead. Starting now means you’ll see results sooner.
              </li>
              <li>
                <strong>“I Don’t Have Time for SEO”:</strong><br /> 
                That’s exactly why we created this ebook—to help you implement powerful strategies in just 5 minutes a day.
              </li>
              <li>
                <strong>“SEO is Too Complicated”:</strong><br /> 
                It doesn’t have to be. With the right guidance, you can focus on what matters most without wasting time on technical jargon.
              </li>
            </ul>
          </div>
        </section>

        {/* Path Section */}
        <section className="bg-white py-16 px-6">
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-semibold mb-6">What You'll Learn in This Ebook</h2>
            <ul className="mx-auto mt-8 max-w-3xl space-y-6">
              <li>
                <strong>✔ Find Quick Wins with Keywords:</strong> Discover long-tail keywords that are easier to rank for and bring in targeted traffic.
              </li>
              <li>
                <strong>✔ Create SEO-Optimized Content Effortlessly:</strong> Use AI tools to generate content without spending hours brainstorming or writing.
              </li>
              <li>
                <strong>✔ Optimize Your Website for Instant Impact:</strong> Learn on-page tweaks that immediately improve your rankings.
              </li>
              <li>
                <strong>✔ Track Results Like a Pro:</strong> Focus on metrics that matter without getting bogged down in data.
              </li>
            </ul>
          </div>
        </section>

        {/* Result Section */}
        <section className="bg-gray-100 py-16 px-6">
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-semibold mb-6">Build a Startup That Grows While You Sleep</h2>
            <p className="text-lg mb-8">
              By following the tips in this ebook, you’ll:
            </p>
            <ul className="mx-auto mt-8 max-w-3xl space-y-6">
              <li>✔ See noticeable improvements in organic traffic within weeks.</li>
              <li>✔ Save hours every week with simplified SEO strategies.</li>
              <li>✔ Create a sustainable growth engine for your startup.</li>
            </ul>
            <p className="text-xl mt-8">
              Don’t let your competitors outpace you.<br /> Take 30 seconds now to invest in your startup’s future.
            </p>
            <form className="bg-white text-black p-6 rounded-md mt-8" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-4 text-lg mb-4 border rounded-md"
                required
              />
              <button
                type="submit"
                className="bg-black text-white py-3 px-6 rounded-md text-lg hover:bg-gray-800 transition"
              >
                Get My Free Ebook
              </button>
            </form>
            {message && (
              <p className="mt-4 text-lg font-medium text-green-500">
                {message}
              </p>
            )}
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default LandingPage;