import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../homepage.css';
import strugglingGuy from '../images/struggling-guy.webp';
import ranqioVideo from '../images/ranqio.mp4';
import HomeMenu from '../components/HomeMenu';

import {
  FaClock,
  FaMoneyBillWave,
  FaHourglassHalf,
  FaChartLine,
  FaHandsHelping,
  FaBullseye,
  FaRocket,
} from 'react-icons/fa';

const Home = () => {
  return (
    <>
      <HomeMenu />

      {/* Hero */}
      <section className="bg-black text-white py-28 px-6">
        <div className="max-w-5xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-8 leading-tight tracking-tight">
            The 5-Minute SEO Solution for Busy Startup Founders
          </h1>
          <p className="text-xl text-gray-200 max-w-xl mx-auto mb-10 leading-relaxed">
            No time for SEO? Our 5-minute tool helps busy startup founders grow revenue fast—see results quicker than ever.
          </p>
          <Link
            to="/signup"
            className="inline-block bg-white text-black py-3 px-8 rounded-full text-base font-medium hover:bg-gray-100 transition"
            onClick={() => {
              if (typeof window !== 'undefined' && window.gtag) {
                window.gtag('event', 'signup_button_click', {
                  event_category: 'engagement',
                  event_label: 'Hero CTA',
                });
              }
            }}
          >
            Try it Free – No credit card required
          </Link>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* Problem Section */}
      <section className="py-24 px-6 bg-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <div>
            <h2 className="text-4xl font-semibold mb-6 text-black leading-tight tracking-tight">
              SEO Takes Time That Busy Founders Don't Have
            </h2>
            <p className="text-lg text-gray-700 mb-10 leading-relaxed">
              As a startup founder, you're juggling countless tasks. Traditional SEO strategies require time and expertise that you simply can't spare.
            </p>
            <ul className="space-y-5 text-base text-gray-800 leading-relaxed">
              <li className="flex items-start">
                <FaClock className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Time-Consuming:</strong> SEO research and implementation can eat up hours of your day.</span>
              </li>
              <li className="flex items-start">
                <FaHourglassHalf className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Delayed Results:</strong> Traditional SEO efforts take months to show impact.</span>
              </li>
              <li className="flex items-start">
                <FaChartLine className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Complex Strategies:</strong> Navigating SEO intricacies requires specialized knowledge.</span>
              </li>
            </ul>
          </div>
          <div>
            <img
              src={strugglingGuy}
              alt="Struggling with time constraints"
              className="w-full h-auto object-cover rounded"
            />
          </div>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* Solution Section */}
      <section className="py-24 px-6 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <video
            src={ranqioVideo}
            controls
            className="w-full h-auto rounded"
            autoPlay
            loop
            muted
          />
          <div>
            <h2 className="text-4xl font-semibold mb-6 text-black leading-tight tracking-tight">
              Introducing Ranqio: SEO Made Easy in Just 5 Minutes
            </h2>
            <p className="text-lg text-gray-700 mb-10 leading-relaxed">
              Ranqio simplifies the complex world of search engine optimization into a quick, easy-to-use platform that delivers actionable insights in just minutes. You don't have to be an SEO expert or spend hours analyzing data; our solution does the heavy lifting for you.
            </p>
            <ul className="space-y-5 text-base text-gray-800 leading-relaxed">
              <li className="flex items-start">
                <FaBullseye className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Instant Keyword Opportunities:</strong> Discover high-impact keywords without the usual hassle.</span>
              </li>
              <li className="flex items-start">
                <FaHandsHelping className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Automated Content Generation:</strong> Generate optimized blog posts that attract traffic.</span>
              </li>
              <li className="flex items-start">
                <FaRocket className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Accelerate Growth:</strong> See quicker results and focus on scaling your business.</span>
              </li>
              <li className="flex items-start">
                <FaMoneyBillWave className="text-black h-5 w-5 mr-3 mt-1" />
                <span><strong>Cost-Effective:</strong> Affordable solution without compromising on quality.</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* Organic Growth Section */}
      <section className="py-24 px-6 bg-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-semibold mb-6 text-black leading-tight tracking-tight">
            Grow Your Revenue Without the Wait
          </h2>
          <p className="text-lg text-gray-700 mb-12 leading-relaxed max-w-2xl mx-auto">
            Traditional SEO takes time to yield results. With Ranqio, you can accelerate your revenue growth by quickly implementing effective SEO strategies, all without the usual effort.
          </p>
          <ul className="space-y-5 text-left max-w-2xl mx-auto text-base text-gray-800 leading-relaxed">
            <li className="flex items-start">
              <FaHourglassHalf className="text-black h-5 w-5 mr-3 mt-1" />
              <span><strong>Save Time:</strong> Implement SEO strategies in minutes, not months.</span>
            </li>
            <li className="flex items-start">
              <FaRocket className="text-black h-5 w-5 mr-3 mt-1" />
              <span><strong>Fast Results:</strong> Begin attracting organic traffic sooner.</span>
            </li>
            <li className="flex items-start">
              <FaChartLine className="text-black h-5 w-5 mr-3 mt-1" />
              <span><strong>Sustainable Growth:</strong> Build a steady stream of leads without constant effort.</span>
            </li>
          </ul>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* How It Works Section */}
      <section className="py-24 px-6 bg-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-semibold mb-12 text-black leading-tight tracking-tight">
            3 Easy Steps to SEO Success
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div>
              <div className="h-12 w-12 mx-auto mb-6 bg-black text-white rounded-full flex items-center justify-center font-medium">
                1
              </div>
              <h3 className="text-xl font-semibold mb-3">Enter Your Keyword</h3>
              <p className="text-base text-gray-700 leading-relaxed">
                Input your primary keyword, and our tool will analyze top-ranking content for you.
              </p>
            </div>
            <div>
              <div className="h-12 w-12 mx-auto mb-6 bg-black text-white rounded-full flex items-center justify-center font-medium">
                2
              </div>
              <h3 className="text-xl font-semibold mb-3">Get Actionable Insights</h3>
              <p className="text-base text-gray-700 leading-relaxed">
                Receive long-tail keyword suggestions and content outlines instantly.
              </p>
            </div>
            <div>
              <div className="h-12 w-12 mx-auto mb-6 bg-black text-white rounded-full flex items-center justify-center font-medium">
                3
              </div>
              <h3 className="text-xl font-semibold mb-3">Generate Content</h3>
              <p className="text-base text-gray-700 leading-relaxed">
                Use our AI to create optimized blog posts that drive traffic, all in minutes.
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* Pricing Section */}
      <section className="py-24 px-6 bg-white">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-semibold mb-12 text-black leading-tight tracking-tight">
            Affordable Plans for Every Founder
          </h2>
          <div className="flex flex-col md:flex-row items-start md:items-stretch justify-center gap-10">
            {/* Free Trial Plan */}
            <div className="border border-gray-200 rounded-lg p-8 flex-1 max-w-sm mx-auto bg-gray-50">
              <h3 className="text-2xl font-semibold mb-4 text-black">Free Trial</h3>
              <p className="text-base text-gray-700 mb-6 leading-relaxed">
                Experience the core features at no cost.
              </p>
              <p className="text-3xl font-bold text-black mb-6">
                $0<span className="text-xl font-normal text-gray-700">/month</span>
              </p>
              <ul className="text-gray-700 text-left space-y-4 mb-8 leading-relaxed">
                <li>✅ 10 Keyword Analyses</li>
                <li>✅ 5 AI-Generated Blog Posts</li>
                <li>❌ Unlimited Access</li>
                <li>❌ Priority Support</li>
              </ul>
              <Link
                to="/signup"
                className="block bg-black text-white py-3 px-6 rounded-full text-base font-medium hover:bg-gray-900 transition"
                onClick={() => {
                  if (typeof window !== 'undefined' && window.gtag) {
                    window.gtag('event', 'signup_button_click', {
                      event_category: 'engagement',
                      event_label: 'Free Trial Button - Pricing',
                    });
                  }
                }}
              >
                Start Free Trial
              </Link>
            </div>
            {/* Paid Plan */}
            <div className="border border-gray-200 rounded-lg p-8 flex-1 max-w-sm mx-auto bg-white">
              <h3 className="text-2xl font-semibold mb-4 text-black">
                Pro Plan
              </h3>
              <p className="text-base text-gray-700 mb-6 leading-relaxed">
                Unlock full access and accelerate your growth.
              </p>
              <p className="text-3xl font-bold text-black mb-6">
                $24.9<span className="text-xl font-normal text-gray-700">/month</span>
              </p>
              <ul className="text-gray-700 text-left space-y-4 mb-8 leading-relaxed">
                <li>✅ Unlimited Keyword Analyses</li>
                <li>✅ Unlimited AI-Generated Posts</li>
                <li>✅ Full Feature Access</li>
                <li>✅ Priority Support</li>
              </ul>
              <Link
                to="/signup"
                className="block bg-black text-white py-3 px-6 rounded-full text-base font-medium hover:bg-gray-900 transition"
                onClick={() => {
                  if (typeof window !== 'undefined' && window.gtag) {
                    window.gtag('event', 'signup_button_click', {
                      event_category: 'engagement',
                      event_label: 'Pro Plan Get Started',
                    });
                  }
                }}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* Testimonials Section */}
      <section className="py-24 px-6 bg-gray-900 text-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-semibold mb-12 leading-tight">
            What Startup Founders Are Saying
          </h2>
          <div className="space-y-12">
            <div className="border border-gray-800 p-8 rounded-lg bg-gray-800">
              <p className="text-xl italic mb-6 text-gray-100 leading-relaxed">
                "Ranqio transformed how I approach SEO. In just a few minutes, I got actionable insights that would've taken me days to compile."
              </p>
              <p className="font-semibold text-white">– Alex T., Tech Startup Founder</p>
            </div>
            <div className="border border-gray-800 p-8 rounded-lg bg-gray-800">
              <p className="text-xl italic mb-6 text-gray-100 leading-relaxed">
                "As a solo founder, time is my most valuable asset. Ranqio's 5-minute SEO tool lets me focus on growing my business, not learning SEO."
              </p>
              <p className="font-semibold text-white">– Jamie L., SaaS Entrepreneur</p>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      {/* Final CTA Section */}
      <section className="py-24 px-6 bg-black text-white text-center">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-semibold mb-6 leading-tight tracking-tight">
            Ready to Grow Your Startup Faster?
          </h2>
          <p className="text-lg text-gray-200 mb-10 leading-relaxed">
            Stop spending countless hours on SEO or delaying it altogether. Let Ranqio empower you to achieve rapid revenue growth with minimal effort.
          </p>
          <Link
            to="/signup"
            className="inline-block bg-white text-black py-3 px-8 rounded-full text-base font-medium hover:bg-gray-100 transition"
            onClick={() => {
              if (typeof window !== 'undefined' && window.gtag) {
                window.gtag('event', 'signup_button_click', {
                  event_category: 'engagement',
                  event_label: 'Bottom CTA',
                });
              }
            }}
          >
            Try it Free – No credit card required
          </Link>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
