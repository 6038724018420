import React, { useState, useEffect } from 'react';
import { fetchBlogPosts } from '../services/api'; 
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { FaCalendarAlt } from 'react-icons/fa';

const BlogPostList = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const result = await fetchBlogPosts();
        const sortedPosts = result.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllPosts();
  }, []);

  if (isLoading) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-lg text-gray-700">Loading blog posts...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (posts.length === 0) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center text-center px-4">
            <p className="text-lg text-gray-700 mb-4">You don't have any blog posts yet.</p>
            <p className="text-md text-gray-600 mb-6">
              Start by searching for keywords to create your first blog post.
            </p>
            <Link
              to="/keywords"
              className="inline-block bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition"
            >
              Search Keywords
            </Link>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Menu />

      {/* Hero Section */}
      <section className="bg-black text-white py-16 px-6">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-5xl font-bold mb-6 leading-tight tracking-tight">
            Latest Blog Posts
          </h2>
          <p className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed">
            Explore your recently created posts.
          </p>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      <section className="py-24 bg-white">
        <div className="container mx-auto px-6 lg:px-12">
          <ul className="space-y-12">
            {posts.map((post) => (
              <li
                key={post._id}
                className="group transition-transform transform hover:-translate-y-1 hover:shadow-lg bg-white rounded-lg shadow border border-gray-200 p-8"
              >
                <Link to={`/blogpost/${post.post_id}`} className="text-black no-underline hover:no-underline">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4 leading-tight group-hover:underline">
                    {post.title}
                  </h3>
                </Link>
                <div className="flex items-center text-gray-600 mb-6">
                  <FaCalendarAlt className="h-5 w-5 mr-2 text-gray-500" />
                  <span className="text-sm">
                    {post.createdAt
                      ? new Date(post.createdAt).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })
                      : ''}
                  </span>
                </div>
                <p className="text-base text-gray-700 leading-relaxed mb-8">
                  {post.content.substring(0, 400)}...
                </p>
                <Link
                  to={`/blogpost/${post.post_id}`}
                  className="inline-block bg-black text-white py-2 px-6 rounded-full text-base font-medium hover:bg-gray-800 transition no-underline"
                >
                  Read More →
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogPostList;