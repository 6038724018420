import React, { useContext } from 'react';
import axiosInstance from '../services/axiosInstance';
import { loadStripe } from '@stripe/stripe-js';
import AuthContext from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51Q2M2FB03OTgBCZpMCDIpxyRb8HUSeqnljFsWHJZnWGROTI5lZIVR1KyjyL4ITYOXkbJoD0Um4UZNiDTvVPiogpg00jmfJMguU'); 

const Pricing = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubscribe = async (priceId) => {
    if (!token) {
      navigate('/signin');
      return;
    }

    const stripe = await stripePromise;

    try {
      const response = await axiosInstance.post('/create-checkout-session', {
        priceId: priceId,
      });

      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect error:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const pricingOptions = [
    {
      title: 'Premium Monthly',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$24.90/month',
      priceId: 'price_1QGQ8XB03OTgBCZp4GZbc7qN',
    },
    {
      title: 'Premium Every 3 Months',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$67.90/3 months',
      priceId: 'price_1QGQ8XB03OTgBCZpFuvXWNmx',
    },
    {
      title: 'Premium Yearly',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$239.90/year',
      priceId: 'price_1QGQ8XB03OTgBCZp7Qj1KnkL',
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <section className="bg-black text-white py-16 px-6">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-5xl font-bold mb-6 leading-tight tracking-tight">
            Choose Your Plan
          </h2>
          <p className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed">
            Pick a plan that suits your startup's needs and scale your growth without limits.
          </p>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      <section className="py-24 bg-white">
        <div className="container mx-auto px-6 lg:px-12">
          <div className="flex flex-col md:flex-row justify-center items-start md:items-stretch gap-8 flex-wrap">
            {/* Trial Plan */}
            <div className="border border-gray-200 rounded-lg p-8 flex-1 max-w-sm mx-auto">
              <h3 className="text-2xl font-semibold mb-4 text-black">Trial Plan</h3>
              <p className="text-base text-gray-700 whitespace-pre-line mb-4">
                - 10 Keyword Searches
                <br />
                - 5 Blog Post Generations
              </p>
              <p className="text-base text-gray-900 font-bold mb-8">Free</p>
              <div className="flex justify-center">
                <p className="text-sm text-gray-500">Available by default for all new users.</p>
              </div>
            </div>

            {/* Premium Plans */}
            {pricingOptions.map((plan, index) => (
              <div key={index} className="border border-gray-200 rounded-lg p-8 flex-1 max-w-sm mx-auto">
                <h3 className="text-2xl font-semibold mb-4 text-black">{plan.title}</h3>
                <p className="text-base text-gray-700 whitespace-pre-line mb-4">
                  {plan.description}
                </p>
                <p className="text-base text-gray-900 font-bold mb-8">
                  {plan.price}
                </p>
                <div className="flex justify-center">
                  <button
                    className="bg-black text-white py-3 px-6 rounded-full text-base font-medium hover:bg-gray-800 transition"
                    onClick={() => handleSubscribe(plan.priceId)}
                  >
                    Subscribe Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
