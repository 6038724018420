import React, { useState, useEffect, useRef } from 'react';
import {
  searchKeywords,
  createBlogPost,
  fetchSavedKeywords,
} from '../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';
import Modal from '../components/Modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import 'react-tooltip/dist/react-tooltip.css';

import countryOptions from '../data/countryOptions';
import languageOptions from '../data/languageOptions';

const KeywordList = () => {
  const [keywords, setKeywords] = useState([]);
  const [loadingSavedKeywords, setLoadingSavedKeywords] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showTrialLimitModal, setShowTrialLimitModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [searching, setSearching] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const hasPerformedInitialSearch = useRef(false);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/');
        return;
      }

      try {
        const response = await axiosInstance.get('/user');
        if (!response.data.onboardingComplete) {
          navigate('/onboarding');
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
        navigate('/');
      }
    };

    checkOnboardingStatus();
  }, [navigate]);

  const fetchAllKeywords = async () => {
    setLoadingSavedKeywords(true);
    try {
      const savedResult = await fetchSavedKeywords();
      setKeywords(savedResult.data);
    } catch (error) {
      console.error('Error fetching keywords:', error);
    } finally {
      setLoadingSavedKeywords(false);
    }
  };

  useEffect(() => {
    if (!hasPerformedInitialSearch.current) {
      if (location.state && location.state.searchQuery) {
        const initialQuery = location.state.searchQuery;
        setSearchQuery(initialQuery);

        if (location.state.country) {
          setSelectedCountry(location.state.country);
        }
        if (location.state.language) {
          setSelectedLanguage(location.state.language);
        }

        handleSearch(initialQuery, location.state.country, location.state.language);
      } else {
        fetchAllKeywords();
      }
      hasPerformedInitialSearch.current = true;
    }
  }, [location.state]);

  const handleSearch = async (query, country, language) => {
    if (!query || !country || !language) {
      setModalMessage('Please enter a keyword, and select both a country and language.');
      setShowTrialLimitModal(true);
      return;
    }

    setSearching(true);
    setShowSearchModal(true);

    try {
      const response = await searchKeywords(
        query,
        country.value,
        language.value,
        country.label,
        language.label
      );

      if (response.redirect_to_profile) {
        setModalMessage(
          'You have reached your trial limit for keyword searches. Please go to your profile to upgrade your subscription.'
        );
        setShowTrialLimitModal(true);
      } else {
        await fetchAllKeywords();
      }
      setSearching(false);
      setShowSearchModal(false);
    } catch (error) {
      console.error('Error searching keywords:', error);
      setSearching(false);
      setShowSearchModal(false);
      setModalMessage(
        'An error occurred while searching for keywords. Please try again.'
      );
      setShowTrialLimitModal(true);
    }
  };

  const getDifficultyLabel = (score) => {
    if (score === null || score === undefined) {
      return { label: 'N/A', className: 'text-gray-500' };
    }
    if (score <= 29) {
      return { label: 'Easy', className: 'text-green-600' };
    } else if (score <= 59) {
      return { label: 'Medium', className: 'text-yellow-600' };
    } else if (score <= 79) {
      return { label: 'Hard', className: 'text-orange-600' };
    } else {
      return { label: 'Very Hard', className: 'text-red-600' };
    }
  };

  const formatSearchVolume = (volume) => {
    if (volume === null || volume === undefined) {
      return 'N/A';
    }
    if (volume < 10) {
      return '< 10';
    } else {
      return volume;
    }
  };

  const handleCreatePost = async (keywordId) => {
    setCreatingPost(true);
    setShowLoadingModal(true);
    setShowTrialLimitModal(false);

    try {
      const response = await createBlogPost(keywordId);
      console.log('Response from createBlogPost:', response);

      if (response.redirect_to_profile) {
        setCreatingPost(false);
        setShowLoadingModal(false);
        setModalMessage(
          'You have reached your trial limit for blog posts. Please go to your profile to upgrade your subscription.'
        );
        setShowTrialLimitModal(true);
        return;
      }

      if (response.post_id) {
        setCreatingPost(false);
        setShowLoadingModal(false);
        navigate(`/blogpost/${response.post_id}`);
      } else {
        console.error('Error: No post ID returned from the API');
        setCreatingPost(false);
        setShowLoadingModal(false);
        setModalMessage('Failed to create blog post. Please try again.');
        setShowTrialLimitModal(true);
      }
    } catch (error) {
      console.error('Error creating blog post:', error);
      setCreatingPost(false);
      setShowLoadingModal(false);
      setModalMessage(
        'An error occurred while creating the blog post. Please try again.'
      );
      setShowTrialLimitModal(true);
    }
  };

  if (loadingSavedKeywords)
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-xl text-gray-700">Loading keywords...</p>
          </div>
        </div>
        <Footer />
      </>
    );

  const sortedKeywords = keywords.sort((a, b) => {
    const dateA = new Date(a.createdAt || 0);
    const dateB = new Date(b.createdAt || 0);

    if (dateB - dateA !== 0) {
      return dateB - dateA;
    }

    const volumeA = a.avgMonthlySearches || 0;
    const volumeB = b.avgMonthlySearches || 0;
    return volumeB - volumeA;
  });

  return (
    <>
      <Menu />

      {/* Modern Hero Section */}
      <section
        className="relative bg-black text-white py-16 px-6 overflow-hidden"
        style={{
          background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.05) 0%, rgba(0,0,0,1) 100%)',
        }}
      >
        <div
          className="absolute inset-0 bg-center bg-cover opacity-10"
          style={{ backgroundImage: "url('/images/hero-pattern-white.svg')" }}
        ></div>
        <div className="max-w-5xl mx-auto text-center relative z-10">
          <h2 className="text-5xl font-bold mb-6 leading-tight tracking-tight">
            Keyword Ideas
          </h2>
          <p className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed">
            Explore keyword opportunities and start crafting optimized content to fuel your startup’s growth.
          </p>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-0" />

      <section className="py-24 bg-white">
        {/* Use the same container width as Menu: px-6 lg:px-12 */}
        <div className="container mx-auto px-6 lg:px-12">
          {/* Search Bar, Country, and Language Selector */}
          <div className="mb-16 flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-6">
            <div className="relative w-full max-w-lg">
              <input
                type="text"
                placeholder="Type a topic or niche to discover keywords"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-gray-300 w-full px-4 pr-12 rounded-md focus:outline-none focus:border-gray-500 text-base h-10"
              />
            </div>
            <div className="relative w-full max-w-xs">
              <Select
                options={countryOptions}
                value={selectedCountry}
                onChange={setSelectedCountry}
                placeholder="Select a Country"
                classNamePrefix="react-select"
              />
            </div>
            <div className="relative w-full max-w-xs">
              <Select
                options={languageOptions}
                value={selectedLanguage}
                onChange={setSelectedLanguage}
                placeholder="Select a Language"
                classNamePrefix="react-select"
              />
            </div>
            <button
              onClick={() => handleSearch(searchQuery, selectedCountry, selectedLanguage)}
              disabled={searching}
              className="bg-black text-white px-6 rounded-md text-base font-medium hover:bg-gray-800 transition focus:outline-none h-10 flex items-center justify-center"
            >
              {searching ? 'Searching...' : 'Search'}
            </button>
          </div>

          <ReactTooltip
            id="searches-per-month-tooltip"
            place="top"
            effect="solid"
            multiline={true}
            render={() => (
              <span>
                The average number of times people search for this keyword per month.<br />
                If you're a new website, focus on keywords with 0-500 monthly volume search.<br />
                Long-tail keywords may have lower search volumes but can be less competitive and more targeted.
              </span>
            )}
          />

          <ReactTooltip
            id="difficulty-score-tooltip"
            place="top"
            effect="solid"
            multiline={true}
            render={() => (
              <span>
                An estimate of how hard it is to rank for this keyword.<br />
                If you're a new website without domain authority, focus on 'Easy' or 'Medium' difficulty keywords to improve your chances of ranking.
              </span>
            )}
          />

          {/* Keywords Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border-t border-b border-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                    Search Date
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                    Keyword
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                    Searches/Month
                    <FaInfoCircle
                      data-tooltip-id="searches-per-month-tooltip"
                      className="inline ml-1 text-gray-500 cursor-pointer"
                    />
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                    Difficulty Score
                    <FaInfoCircle
                      data-tooltip-id="difficulty-score-tooltip"
                      className="inline ml-1 text-gray-500 cursor-pointer"
                    />
                  </th>
                  <th className="px-6 py-3 text-center text-sm font-semibold text-gray-900">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {sortedKeywords.map((keyword, index) => (
                  <tr key={index} className="hover:bg-gray-50 transition">
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {keyword.createdAt
                        ? moment(keyword.createdAt).format('MMM Do, YYYY')
                        : 'N/A'}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {keyword.keyword}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {formatSearchVolume(keyword.avgMonthlySearches)}
                    </td>
                    <td className="px-6 py-4 text-sm">
                      <span className={getDifficultyLabel(keyword.difficultyScore).className}>
                        {getDifficultyLabel(keyword.difficultyScore).label}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-center">
                      <button
                        onClick={() => handleCreatePost(keyword._id)}
                        disabled={creatingPost}
                        className="bg-black text-white py-2 px-4 rounded-md text-sm font-medium hover:bg-gray-800 transition focus:outline-none"
                      >
                        {creatingPost ? 'Creating...' : 'Create Blog Post'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Search Loading Modal */}
          {showSearchModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-md shadow-lg p-8 max-w-md w-full">
                <h3 className="text-xl font-semibold text-gray-900 mb-4 text-center">
                  Analyzing Keyword...
                </h3>
                <div className="text-gray-700 mb-6 text-center space-y-4 text-base leading-relaxed">
                  <p>We're analyzing your keyword and gathering relevant data.</p>
                  <p>
                    Our AI is generating valuable long-tail keyword suggestions by examining search volumes and competition.
                  </p>
                  <p>Hang tight, this may take just a few moments.</p>
                </div>
                <div className="flex justify-center">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          )}

          {/* Blog Post Creation Loading Modal */}
          {showLoadingModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-md shadow-lg p-8 max-w-sm w-full">
                <h3 className="text-xl font-semibold text-gray-900 mb-6 text-center">
                  Creating Blog Post...
                </h3>
                <div className="flex justify-center">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          )}

          {/* Trial Limit Modal */}
          {showTrialLimitModal && (
            <Modal
              message={modalMessage}
              onClose={() => setShowTrialLimitModal(false)}
              onAction={() => navigate('/profile')}
              actionText="Go to Profile"
            />
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default KeywordList;